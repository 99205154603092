import { getPublicConfiguration } from '@config/universal-config';

import IndexPage from './index';

export default IndexPage;

export const getServerSideProps = () => {
    return {
        props: {
            publicConfiguration: getPublicConfiguration()
        }
    };
};
